import bcrypt from 'bcryptjs';

const comparePassword = async (password: string, storedpassword: string) => {
    return await bcrypt.compare(password, storedpassword);
}

const createPassword = async (password: string) => {
    return await bcrypt.hash(password, 10);
}

const compareSyncPassword = async (password: string, storedpassword: string) => {
    return await bcrypt.compareSync(password, storedpassword);
}

export { comparePassword, createPassword, compareSyncPassword }