const USER_TYPES = {
	ADMIN: "admin",
	GUEST: "guest",
	USER: "user"
}

const USER_ROLE = {
	1: "super admin",
	2: "admin",
	3: "user"
}

const PERMISSION = {
	1: ["dashboard", "organisation"],
	2: ["dashboard"],
}

const COUNTRY_CODE = '+1'

// send password via email
const SEND_MAIL_API = "https://us-central1-sybica-80359.cloudfunctions.net/sendAuthenticationEmail";
// const SEND_MAIL_API = "http://localhost:5000/sybica-80359/us-central1/sendAuthenticationEmail";

export { USER_TYPES, USER_ROLE, PERMISSION, SEND_MAIL_API, COUNTRY_CODE };
