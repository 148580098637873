/* eslint-disable react-hooks/exhaustive-deps */
import RedirectComp from 'Components/Redirect';
import useLocalStorage from 'Hooks/useLocalStorage';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { getCookie, removeCookie } from 'Utils/cookies';
import { findOne } from 'Utils/firebase.functions';

function RedirectPage(props: any) {
	const { token } = props;
	if (token && props?.loggedInUsr?.firstTime) {
		return <RedirectComp path="/admin/change-password" state={{ isAdmin: props?.location?.state?.isAdmin }} />
	} else if (token && props?.loggedInUsr && ["1", "2"].includes(props.loggedInUsr.role)) {
		return <RedirectComp path="/admin/dashboard" />
	} else if (token && props?.loggedInUsr && ["3"].includes(props.loggedInUsr.role)) {
		return <RedirectComp path="/login" />
	} else if (!token) {
		return <RedirectComp path="/login" />
	}
	return <div>Loading....</div>
}

export const Protected = (props: any) => {
	const [allow, isAllow] = useState(true);
	const [isRedirect, setRedirect] = useState(false);
	const [storedValue] = useLocalStorage("token")
	const [loggedInUsr] = useLocalStorage("loggedInUser")
	const [rememberMe, setRememberMe] = useLocalStorage("rememberMe");
	const [expiryStartTime, setExpiryTime] = useLocalStorage("expiryTime");

	useEffect(() => {
		if (storedValue) {
			isAllow(true);
		} else {
			isAllow(false);
		}
	}, [storedValue])

	useEffect(() => {
		if (loggedInUsr?.id && ["1", "2", "3"].includes(loggedInUsr?.role)) {
			findUser()
		}
	}, [loggedInUsr])

	const { children } = props;
	const findUser = async () => {
		const usr = await findOne("users", "id", loggedInUsr?.id);
		const cookieRememberme = await getCookie("rememberMe")
		if (cookieRememberme && cookieRememberme === "true") {
			setRememberMe(true)
			removeCookie("rememberMe")
		}
		else if (cookieRememberme && cookieRememberme === "false") {
			setExpiryTime(Date.now() + 0 * 60 * 1000);
			setRememberMe(false)
			removeCookie("rememberMe")
		}
		const startTime = expiryStartTime;
		if (!rememberMe) {
			if (startTime) {
				const time = 24 * 60;
				const timeInMilisec = Number(time) * 60 * 1000;
				const expiryTime = Number(startTime) + Number(timeInMilisec);
				if (Number(expiryTime) <= Number(Date.now() + 0 * 60 * 1000)) {
					setRedirect(true)
				}
			}
		}
		if (!usr && !loggedInUsr?.guest) {
			setRedirect(true)
		}
		if (usr?.isDelete) {
			setRedirect(true)
		}

	}
	if (!isRedirect) {
		findUser();
	}
	if (isRedirect) {
		return <RedirectComp path="/logout" />
	}
	if (loggedInUsr?.firstTime) {
		if (props?.location?.pathname !== "/admin/change-password")
			return <RedirectComp path="/admin/change-password" state={{ isAdmin: props?.location?.state?.isAdmin }} />
	}
	else if (!loggedInUsr?.role) {
		if (props?.location?.pathname && props?.location?.pathname.includes("/admin"))
			return <RedirectComp path="/" />
	}
	else if (loggedInUsr?.role) {
		if (["3"].includes(loggedInUsr?.role) && props?.location?.pathname && props?.location?.pathname.includes("/admin"))
			return <RedirectComp path="/" />
	}
	if (allow) {
		return children;
	}
	return (
		<Route render={() => {
			return (
				<RedirectPage loggedInUsr={loggedInUsr} token={storedValue} />
			);
		}}
		/>
	);
}

export default Protected;
