import { createContext, Context } from 'react'

interface ContextProps {
	isLoggedIn: boolean
	isAdmin: boolean
	isSuperAdmin: boolean
	isUser: boolean
}

const DefContext: ContextProps = { isLoggedIn: false, isAdmin: false, isSuperAdmin: false, isUser: false }

const AuthContext: Context<ContextProps> = createContext(DefContext)

export default AuthContext
