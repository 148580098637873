import axios from 'axios';
import { SEND_MAIL_API } from './constants';

export const sendMail = async (from: string, email: string, password: string, type: string, orgAuthCode: string, organisation: string, name: string, logourl: string, role: string) => {
    try {
        const send = await axios({
            method: 'post',
            url: `${SEND_MAIL_API}`,
            data: {
                from,
                email,
                authCode: password,
                orgAuthCode,
                type,
                organisation: organisation,
                name,
                logourl,
                role,
            }
        });
        return send;
    } catch (err) {
        return err
    }
}
