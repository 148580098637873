import AuthContext from 'Context/AuthContext';
import useLocalStorage from 'Hooks/useLocalStorage';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { findOne } from 'Utils/firebase.functions';
import logoImg from '../../images/logo.svg';

export default function Header() {
	const [user] = useLocalStorage("loggedInUser");
	const [logoImage, setLogoImage] = useState('')
	const [classes, setClass] = useState({ admin: "", user: "", report: "" })
	const [isAdminPath, setAdminPath] = useState(false)
	const [isLogout, setLogout] = useState(false)
	useEffect(() => {
		const path = window.location.pathname;
		if (path === "/admin/dashboard") {
			setClass({ admin: "", user: "inactive", report: "inactive" })
			setAdminPath(true)
		} else if (path === "/admin/organisation") {
			setClass({ admin: "inactive", user: "", report: "inactive" })
			setAdminPath(true)
		} else if (path === "/admin/report") {
			setClass({ admin: "inactive", user: "inactive", report: "" })
			setAdminPath(true)
		}
	}, [window.location.pathname])

	useEffect(() => {
		const getLogo = async () => {
			let usrData = await findOne('users', 'id', user.id)
			if (!usrData) {
				usrData = await findOne('guest', 'id', user.id)
			}
			const org = await findOne('organisations', 'id', usrData.organisation)
			if (org?.url)
				setLogoImage(org.url);
		}
		if (user?.organisation) {
			getLogo();
		}
	}, [])

	const changeClass = (param: string) => {
		if (param === "admin") {
			setClass({ admin: "active", user: "", report: "" })
		} else if (param === "user") {
			setClass({ admin: "", user: "active", report: "" })
		} else {
			setClass({ admin: "", user: "", report: "active" })
		}
	}

	const confirmLogout = () => {
		setLogout(true);
	}
	const handleClose = () => {
		setLogout(false);
	}
	return (
		<div className="container header_container">
			{isLogout && (
				<Modal
					show={isLogout}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Header closeButton>
						<Modal.Title>Sign Out</Modal.Title>
					</Modal.Header>
					<Modal.Body>Please confirm if you want to sign out</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancel
				  </Button>
						<Link to="/logout" onClick={handleClose} ><Button variant="secondary" onClick={handleClose}>Confirm</Button></Link>
					</Modal.Footer>
				</Modal>
			)}
			<Row>
				<Col md="12" className="d-flex justify-content-center flex-column align-items-center">
					<AuthContext.Consumer>
						{(props) => (
							<>
								{(props.isLoggedIn) && (
									<div className="logo">
										<img src={logoImage || logoImg} alt="" />
									</div>
								)}
							</>
						)}
					</AuthContext.Consumer>

					<div className="logo_bottom_text">
						<Navbar>
							<Navbar.Brand href="#home">Covid-19 Pre-Screening</Navbar.Brand>
						</Navbar>
					</div>
				</Col>



			</Row>




			<Row>
				<Col md="4" lg={3} className="d-flex align-items-center">
					<AuthContext.Consumer>
						{(props) => (
							<>
								{(props?.isSuperAdmin || props?.isAdmin) && (
									<Navbar className="m-0 p-0">
										<Navbar.Collapse className="justify-content-start align-self-start m-0 p-0">
											{(props?.isSuperAdmin || props?.isAdmin) && isAdminPath && (<>
												<Nav.Link className="p-0 start_sc">
													<Link to="/" onClick={() => setAdminPath(false)}>Start Pre-Screening</Link>
												</Nav.Link>
											</>)}
											{(props?.isSuperAdmin || props?.isAdmin) && !isAdminPath && (<>
												<Nav.Link className="dashboard_icon_link">
													<Link to="/admin/dashboard" onClick={() => setAdminPath(true)}><i className="fa fa-tachometer"></i> Dashboard</Link>
												</Nav.Link>
											</>)}
										</Navbar.Collapse>
									</Navbar>
								)}
							</>
						)}
					</AuthContext.Consumer>
				</Col>
				<Col md="6">
					<AuthContext.Consumer>
						{(props) => (
							<>
								{(props?.isSuperAdmin || props?.isAdmin) && (
									<Navbar>
										<Navbar.Collapse className="justify-content-center">
											<Nav className="dash_and_org_btn" defaultActiveKey="/admin/dashboard">
												{props?.isSuperAdmin && (
													<>
														<Nav.Link className={`${classes.admin}`}>
															<Link to="/admin/dashboard" onClick={() => changeClass("admin")} className={`${classes.admin}`}>Admin</Link>
														</Nav.Link>
														<Nav.Link className={`${classes.user}`}>
															<Link to="/admin/organisation" onClick={() => changeClass("user")} className={`${classes.user}`}>Organizations</Link>
														</Nav.Link>
														<Nav.Link className={`${classes.report}`}>
															<Link to="/admin/report" onClick={() => { changeClass("report"); }} className={`${classes.user}`}>Report</Link>
														</Nav.Link>
													</>
												)}
												{props?.isAdmin && isAdminPath && (
													<>
														<Nav.Link className={`${classes.admin}`}>
															<Link to="/admin/dashboard" onClick={() => changeClass("admin")} className={`${classes.admin}`}>User</Link>
														</Nav.Link>
														<Nav.Link className={`${classes.report}`}>
															<Link to={{ pathname: "/admin/report", state: { admin: true } }} onClick={() => { changeClass("report"); }} className={`${classes.user}`}>Report</Link>
														</Nav.Link>
													</>
												)}
											</Nav>
										</Navbar.Collapse>
									</Navbar>
								)}
							</>
						)}
					</AuthContext.Consumer>
				</Col>
				<Col md={2} lg={3} className="d-flex align-items-center">
					<Navbar className="m-0 p-0">
						<AuthContext.Consumer>
							{(props) => (
								<>
									<Navbar.Collapse className="justify-content-end justify-content-md-end align-self-end p-0" style={{ marginRight: 16 }}>
										{props?.isLoggedIn && (<>
											<Nav.Link className="p-0">
												<span onClick={confirmLogout}><i className="fa fa-sign-out logout-btn"></i> Sign out</span>
											</Nav.Link>
										</>)}
										{/* )} */}
									</Navbar.Collapse>
								</>
							)}
						</AuthContext.Consumer>
					</Navbar></Col>

			</Row>

		</div >

	)
}
