import { createPassword } from "./bcrypt";
import { auth, firestore, storageRef } from "./firebase.config";
import { sendMail } from "./request";
import * as firebase from 'firebase/app';

export const create = async (table, obj) => {
	try {
		const ref = await firestore.collection(`${table}`).doc();
		const pwd = await createPassword(obj.password);
		// const firstTime = obj.role === "3" ? false : true;
		const firstTime = true;
		await firestore.collection(`${table}`).doc(ref.id).set({
			...obj,
			password: pwd,
			firstTime: obj.createdBy === 'frontend' ? false : firstTime,
			id: ref.id,
			originalPwd: obj.password,
			isDelete: false,
			createdAt: new Date().getTime(),
			updatedAt: new Date().getTime(),
		})
		let mailRes = 200;
		if (obj.createdBy !== 'frontend') {
			mailRes = await sendMail(obj.organisations.email, obj.email, obj.password, 'auth', obj.organisations.authcode, obj.organisations.name, obj.name, obj.organisations.url, obj.role);
			mailRes = mailRes?.data?.code;
		}
		return { res: ref.id, success: true, message: "Successfully created", emailSent: mailRes?.data?.code }
	} catch (e) {
		return { res: e, success: false, message: e.message };
	}
}

export const createOrganization = async (table, obj) => {
	try {
		const existAuthCode = await findOne(`${table}`, 'authcode', obj.authcode);
		if (existAuthCode) {
			return { res: existAuthCode, success: false, message: "Organization code is already exists" }
		}
		const ref = await firestore.collection(`${table}`).doc();
		await firestore.collection(`${table}`).doc(ref.id).set({
			...obj,
			id: ref.id,
			isDelete: false,
			createdAt: new Date().getTime(),
			updatedAt: new Date().getTime()
		})
		return { res: ref.id, success: true, message: "Successfully created" }
	} catch (e) {
		return { res: e, success: false, message: e.message };
	}
}

export const createOrg = async (table, obj) => {
	try {
		const ref = await firestore.collection(`${table}`).doc();
		await firestore.collection(`${table}`).doc(ref.id).set({
			...obj,
			id: ref.id,
			isDelete: false,
			createdAt: new Date().getTime(),
			updatedAt: new Date().getTime()
		})
		return { res: ref.id, success: true, message: "Successfully created" }
	} catch (e) {
		return { res: e, success: false, message: e.message };
	}
}

export const update = async (table, obj, id) => {
	try {
		await firestore.collection(`${table}`).doc(id).update({
			...obj,
			updatedAt: new Date().getTime(),
		});
		return { res: id, success: true, message: "Successfully updated" }
	} catch (e) {
		return { res: e, success: false, message: e.message, emailSent: 200 };
	}
}
export const set = async (table, obj, id) => {
	try {
		const doc = await firestore.collection(`${table}`).doc(id).set({
			...obj
		})
		return doc.id;
	} catch (e) {
		return e;
	}
}

export const findAll = async (table, orderBy = "name") => {
	try {
		const data = []
		const doc = await firestore.collection(`${table}`).orderBy(`${orderBy}`).get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					data.push(docd.data());
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findOne = async (table, fieldName, value) => {
	try {
		let data = null;
		const doc = await firestore.collection(`${table}`).where(`${fieldName}`, '==', `${value}`).limit(1).get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					data = docd.data();
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const createUser = async (user) => {
	try {
		const created = await auth.createUserWithEmailAndPassword(user.email, user.password);
		const data = await findOne('users', 'email', user.email);
		let emailSent = 200;
		let id = "";
		if (data === null) {
			const resp = await create('users', user);
			emailSent = resp.emailSent;
			id = resp.res
		} else {
			const pwd = await createPassword(user.password);
			await update('users', { ...user, password: pwd, firstTime: true }, data.id);
			id = data.id;
		}
		auth.onAuthStateChanged(function (user) { return user });
		return { res: created, success: true, message: "user successfully created", emailSent, id }
	} catch (err) {
		return { res: err, success: false, message: err.message };
	}
}

export const signIn = async ({ email, password }) => {
	try {
		const signin = await auth.signInWithEmailAndPassword(email, password);
		const loggedInUser = await findOne('users', 'email', email)
		let org = null;
		if (loggedInUser?.organisation) {
			org = await findOne('organisations', 'id', loggedInUser.organisation)
		}
		return { res: signin, success: true, message: "Successfully signed In", resp: loggedInUser, org }
	} catch (err) {
		console.log("Error", err);
		return { res: err, success: false, message: err.message };
	}
}

export const signInAdmin = async ({ email, password }) => {
	try {
		const signin = await auth.signInWithEmailAndPassword(email, password);
		const loggedInUser = await findOneUser('users', 'email', email)
		return { res: signin, success: true, message: "Successfully signed In", resp: loggedInUser }
	} catch (err) {
		console.log("Error", err);
		return { res: err, success: false, message: err.message };
	}
}

export const findOneUser = async (table, fieldName, value) => {
	try {
		let data = null;
		const doc = await firestore.collection(`${table}`).where(`${fieldName}`, '==', `${value}`).limit(1).get();
		if (doc) {
			doc.forEach(async function (docd) {
				if (docd.data()) {
					const document = docd.data();
					const organisations = await firestore.collection(`organisations`).where(`id`, "==", `${document.organisation}`).limit(1).get();
					if (organisations) {
						organisations.forEach(function (org) {
							if (org.data()) {
								document.org = org.data();
							}
						})
					}
					data = document;
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const signOut = async () => {
	try {
		const sign = await auth.signOut()
		return { res: sign, success: true, message: "Successfully logged out" }
	} catch (er) {
		return { res: er, success: false, message: er.message };
	}
}

export const currentUser = async () => {
	const user = auth.currentUser;
	return user;
}

export const setPassword = async (password, id) => {
	try {
		const user = auth.currentUser;
		const res = await user.updatePassword(password)
		const pwd = await createPassword(password);
		await update('users', { password: pwd, firstTime: false }, id);
		return { res: res, success: true, message: "Password successfully changed" }
	} catch (err) {
		return { res: err, success: false, message: err.message }
	}
}

export const findAllWithRole = async (table, role) => {
	try {
		const data = []
		const doc = await firestore.collection(`${table}`).where("role", "==", `${role}`).get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					data.push(docd.data());
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findAllWithId = async (table, fieldName, value) => {
	try {
		const data = []
		const doc = await firestore.collection(`${table}`).where(`${fieldName}`, "==", `${value}`).get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					data.push(docd.data());
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const createAndsignedInGuest = async (obj) => {
	try {
		const table = "guest";
		const otp = await getRndInteger(100000, 999999);
		const ref = await firestore.collection(`${table}`).doc();
		let guestOrg = await findOne(`organisations`, 'authcode', 'guest');
		// if (!user) {
		await firestore.collection(`${table}`).doc(ref.id).set({
			name: obj.name,
			phone: obj.phone,
			email: obj.email,
			id: ref.id,
			otpExpiryTime: Date.now() + 10 * 60 * 1000,
			createdAt: new Date().getTime(),
			updatedAt: new Date().getTime(),
			otp: otp,
			organisation: guestOrg.id,
			organisations: guestOrg,
			selectedOrganisationId: obj.selectedOrganisationId,
			location: obj.location,
		})
		const user = { ...obj, id: ref.id }
		// }
		const mailRes = await sendMail(guestOrg.email, obj.email, otp, "otp", ' ', guestOrg.name, obj.name, guestOrg.url, '');
		console.log("mailRes:: ", mailRes);
		return { res: user, success: true, message: "Successfully created", emailSent: mailRes?.data?.code }
	} catch (e) {
		return { res: e, success: false, message: e.message };
	}
}

export const getRndInteger = async (min, max) => {
	return Math.floor(Math.random() * (max - min)) + min;
}

export const uploadFile = async (image, path) => {
	try {
		const randomDigit = await getRndInteger(1000, 9999);
		const imageName = `${Date.now()}${randomDigit}.${image.name.split('.').pop()}`
		await storageRef.ref(`${path}${imageName}`).put(image)
		const url = await storageRef.ref(`${path}`).child(imageName).getDownloadURL()
		return url;
	} catch (er) {
		return er
	}
}

export const findAllAdminAndUsers = async (fieldName, value) => {
	try {
		const data = []
		const doc = await firestore.collection(`users`).where(`${fieldName}`, "==", `${value}`).get();
		if (doc) {
			doc.forEach(async function (docd) {
				if (docd.data()) {
					const document = docd.data();
					const organisations = await firestore.collection(`organisations`).where(`id`, "==", `${document.organisation}`).limit(1).get();
					if (organisations) {
						organisations.forEach(function (org) {
							if (org.data()) {
								document.org = org.data();
							}
						})
					}
					data.push(document);
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findAllUsers = async (table, where) => {
	try {
		const data = []
		let query = firestore.collection(`${table}`);
		for (let i = 0; i < where.length; i += 1) {
			query = query.where(`${where[i].field}`, `${where[i].operator}`, `${where[i].value}`)
		}
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					if (!document.isDelete) {
						data.push(document);
					}
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findAllOrganisation = async () => {
	try {
		const data = []
		let query = firestore.collection(`organisations`);
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					if (!document.isDelete) {
						data.push(document);
					}
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findAllOrganisationWithOutguest = async () => {
	try {
		const data = []
		let query = firestore.collection(`organisations`);
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					if (!document.isDelete && document.authcode !== 'guest') {
						data.push(document);
					}
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const downloadReport = async () => {
	try {
		let admins = [];
		let Users = [];
		const org = await findAll('organisations');
		const users = await findAll('users');
		const locations = await findAll('locations');
		Users = [...Users, ...users.filter((u) => u.role === "3")]
		admins = [...admins, ...users.filter((u) => u.role === "2")]
		const newAdminData = admins.map((u) => ({
			...u,
			org: org.find(o => o.id === u.organisation),
			locationData: u.location.map((loc) => locations.find(lc => lc.id === loc))
		}))
		newAdminData.sort((a, b) => (a.org.name > b.org.name) ? 1 : ((b.org.name > a.org.name) ? -1 : 0));
		const newUsrData = Users.map((u) => ({
			...u,
			org: org.find(o => o.id === u.organisation),
			locationData: u.location.map((loc) => locations.find(lc => lc.id === loc))
		}))
		newUsrData.sort((a, b) => (a.org.name > b.org.name) ? 1 : ((b.org.name > a.org.name) ? -1 : 0));
		return { org: org, admins: newAdminData, users: newUsrData };
	} catch (e) {
		return e;
	}
}

export const createAndUpdateLocation = async (locationArr, orgId) => {
	try {
		const table = "locations";
		console.log(locationArr);
		if (locationArr.length > 0) {
			for (let i = 0; i < locationArr.length; i += 1) {
				const ref = await firestore.collection(`${table}`).doc();
				let location = await findOneWithWhere(table, [{ field: "name", operator: "==", value: locationArr[i].trim() }, { field: "organisationId", operator: "==", value: orgId }]);
				if (!location) {
					await firestore.collection(`${table}`).doc(ref.id).set({
						name: locationArr[i].trim(),
						id: ref.id,
						organisationId: orgId,
						createdAt: new Date().getTime(),
						updatedAt: new Date().getTime(),
					})
				}
			}
		}
		return { res: true, success: true, message: "Location successfully updated" }
	} catch (e) {
		return { res: e, success: false, message: e.message };
	}
}


export const findOneWithWhere = async (table, where) => {
	try {
		let data = null;
		let query = firestore.collection(`${table}`);
		for (let i = 0; i < where.length; i += 1) {
			query = query.where(`${where[i].field}`, `${where[i].operator}`, `${where[i].value}`)
		}
		const doc = await query.limit(1).get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					data = docd.data();
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}


export const findAllWithWhere = async (table, where) => {
	try {
		const data = [];
		let query = firestore.collection(`${table}`);
		for (let i = 0; i < where.length; i += 1) {
			query = query.where(`${where[i].field}`, `${where[i].operator}`, `${where[i].value}`)
		}
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					data.push(document);
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findAllWithWhereIn = async (table, field, arr, type) => {
	try {
		const data = [];
		let query = firestore.collection(`${table}`);
		query = query.where(`${field}`, `array-contains-any`, arr).where("role", "==", "3")
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					if (!document.isDelete) {
						data.push(document);
					}
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const findAllWithIn = async (table, field, arr, type) => {
	try {
		const data = [];
		let query = firestore.collection(`${table}`);
		query = query.where(`${field}`, `in`, arr)
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					if (!document.isDelete) {
						data.push(document);
					}
				}
			});
		}
		return data;
	} catch (e) {
		return e;
	}
}

export const resetPassword = async (email) => {
	try {
		const data = await findOne('users', 'email', email);
		if (data === null) {
			return { res: false, success: false, message: "Your email address not registered yet" }
		}
		// if (data?.firstTime === true) {
		//     return { res: false, success: false, message: "Your email address is not confimed yet" }
		// }
		if (data?.isDelete === true) {
			return { res: false, success: false, message: "Your email address is deleted please contact your administrator" }
		}
		const created = await auth.sendPasswordResetEmail(email);
		return { res: created, success: true, message: "reset password link send on your email address " }
	} catch (err) {
		return { res: err, success: false, message: err.message };
	}
}

export const signInWithPhone = async (phone, recaptcha) => {
	try {
		const authenticate = await auth.signInWithPhoneNumber(phone, recaptcha);
		return { res: authenticate, success: true, message: "reset password link send on your email address/phone number " }
	} catch (err) {
		return { res: err, success: false, message: err.message };
	}
}

export const verifyCredentailWithPhone = async (recaptcha, code) => {
	try {
		const credential = await firebase.auth.PhoneAuthProvider.credential(recaptcha, code)
		const authVerify = await auth.signInWithCredential(credential);
		return { res: authVerify, success: true, message: "Successfully signed in" }
	} catch (err) {
		return { res: err, success: false, message: err.message };
	}
}

export const findAllWithidWhere = async (table, where) => {
	try {
		const data = [];
		const ids = []
		let query = firestore.collection(`${table}`);
		for (let i = 0; i < where.length; i += 1) {
			query = query.where(`${where[i].field}`, `${where[i].operator}`, `${where[i].value}`)
		}
		const doc = await query.get();
		if (doc) {
			doc.forEach(function (docd) {
				if (docd.data()) {
					const document = docd.data();
					data.push(document);
					ids.push(document.id);
				}
			});
		}
		return { data, ids };
	} catch (e) {
		return e;
	}
}

export const findAllScreening = async (startDate, endDate, usersData, organisations, locations, guest) => {
	try {
		const data = []
		const guestData = []
		const doc = await firestore.collection(`screening`).where('createdAt', '>=', Number(startDate)).where('createdAt', '<=', Number(endDate)).orderBy(`createdAt`).get();
		if (doc) {
			doc.forEach(function (docd) {
				let document = docd.data();
				if (document) {
					const guests = guest.find((o) => o.id === document.createdBy)
					const status = document["health-care-provider"] && document["health-care-provider"] === "No" ? "Pass" : "Fail";
					let questions = "";
					if (status === "Fail") {
						if (document["health-care-provider"] && document["health-care-provider"] === "Yes") {
							questions += " health-care-provider = Yes";
						} else if (document["physical-contact"] && document["physical-contact"] === "Yes") {
							questions += " physical-contact = Yes";
						} else if (document["travelled-canada"] && document["travelled-canada"] === "Yes") {
							questions += " travelled-canada = Yes";
						} else if (document["symptoms-moderate"]) {
							const symp = document["symptoms-moderate"]
							const sympJson = JSON.parse(symp);
							const keys = Object.keys(sympJson);
							for (let i = 0; i < keys.length; i += 1) {
								if (sympJson[keys[i]] === "Yes") {
									questions += `${keys[i]} = yes`;
								}
							}
						} else if (document["symptoms"]) {
							const symp = document["symptoms"]
							const sympJson = JSON.parse(symp);
							const keys = Object.keys(sympJson);
							for (let i = 0; i < keys.length; i += 1) {
								if (sympJson[keys[i]] === "Yes") {
									questions += `${keys[i]} = yes`;
								}
							}
						}
					}
					if (usersData.ids.indexOf(document.createdBy) !== -1) {
						const users = usersData.data.find((o) => o.id === document.createdBy)
						const org = organisations.find((o) => o.id === users.organisation)
						const locationData = users.location.map((loc) => locations.find(lc => lc.id === loc))
						document = { ...document, users: users, organization: org, locationData: locationData, status, questions };
						data.push(document);
					}
					if (guests) {
						const org = organisations.find((o) => o.id === guests.selectedOrganisationId)
						const location = locations.find((o) => o.id === guests.location)
						document = { ...document, users: guests, organization: org, locationData: location, status, questions };
						guestData.push(document)
					}
				}
			});
		}
		return { data, guestData };
	} catch (e) {
		return e;
	}
}

export const downloadScreeningData = async (organisationId, startDate, endDate) => {
	const usersData = await findAllWithidWhere('users', [{ field: "organisation", operator: "==", value: organisationId }]);
	const org = await findAll('organisations');
	const guest = await findAll('guest');
	const locations = await findAll('locations');
	const screeningData = await findAllScreening(startDate, endDate, usersData, org, locations, guest);
	return screeningData;
}
