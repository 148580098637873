import firebase from 'firebase/app'
// import * as firebaseui from "firebaseui";
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const config = {
    apiKey: 'AIzaSyD0zkoJajmB1K3HEDLY0WKV_UMJjbhtq_4',
    authDomain: 'sybica-covid-screening.firebaseapp.com',
    projectId: 'sybica-covid-screening',
    type: 'service_account',
    databaseURL: 'https://sybica-covid-screening.firebaseio.com',
    storageBucket: "sybica-covid-screening.appspot.com",
}
firebase.initializeApp(config)

export const databaseRef = firebase.database().ref()
export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const timestamp = firebase.database.ServerValue.TIMESTAMP
export const storageRef = firebase.storage()

export const uiConfig = {
    signInSuccessUrl: "https://covid-19.sybica.com/",
    signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
    tosUrl: "https://covid-19.sybica.com/"
};

// const ui = new firebaseui.auth.AuthUI(firebase.auth());
// ui.start("#firebaseui-auth-container", uiConfig);
