import React from 'react'
import { Redirect } from "react-router-dom"


export default function RedirectComp(props: any) {

    return (
        <div>
            <Redirect to={{
                pathname: props.path,
                state: { ...props.state }
            }} />
        </div>
    )
}
