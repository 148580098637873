// REACT
import React, { lazy, Suspense } from 'react'

// ROUTER
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// STRINGS
import Strings from 'Lang/Strings.json'

// CONTEXTO
import MainContext from 'Context/MainContext'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles.scss';
import Header from 'Components/Header';
import Protected from './Protected';
import Footer from 'Components/Footer';
import Loader from 'Components/Loader';
import AuthContext from 'Context/AuthContext';
import useLocalStorage from 'Hooks/useLocalStorage';

// PAGINAS
const Index = lazy(() => import('Pages'))
const Screening = lazy(() => import('Pages/screening'))
// const ScreeningConditions = lazy(() => import('Pages/screening-condtions'))
// const Symptoms = lazy(() => import('Pages/symptoms'))
const Login = lazy(() => import('Pages/Login'))
const Admin = lazy(() => import('Pages/admin/Login'))
const Guest = lazy(() => import('Pages/guest/Login'))
const Otp = lazy(() => import('Pages/Otp'))
const Dashboard = lazy(() => import('Pages/admin/Dashboard'))
const Organisation = lazy(() => import('Pages/admin/Organisation'))
const Report = lazy(() => import('Pages/admin/Report'))
const Results = lazy(() => import('Pages/screening-result'));
const ChangePwd = lazy(() => import('Pages/ChangePwd'))
const ResetPassword = lazy(() => import('Pages/ResetPassword'))
const Signup = lazy(() => import('Pages/Signup'))
const VerifyOtp = lazy(() => import('Pages/VerifyOtp'))
const Logout = lazy(() => import('Pages/Logout'))


// ESTADO
interface AppState {
	langCode: string
	lang: ILangPackage
}

// ESTADO POR DEFECTO
const DefState: AppState = {
	langCode: 'ES',
	lang: Strings.es,
}

interface AuthState {
	isLoggedIn: boolean
	isSuperAdmin: boolean
	isAdmin: boolean
	isUser: boolean
}

// ESTADO POR DEFECTO


const App: React.FC = () => {
	const [loggedIn] = useLocalStorage("loggedInUser");
	const DefAuthState: AuthState = {
		isLoggedIn: loggedIn ? true : false,
		isSuperAdmin: loggedIn?.role && ["1"].includes(loggedIn.role) ? true : false,
		isAdmin: loggedIn?.role && ["2"].includes(loggedIn.role) ? true : false,
		isUser: loggedIn?.role && ["3"].includes(loggedIn.role) ? true : false,
	}
	return (
		<MainContext.Provider value={{ ...DefState }}>
			<Suspense fallback={<Loader></Loader>}>
				<AuthContext.Provider value={{ ...DefAuthState }} >
					<BrowserRouter>
						<Header />
						<Switch>
							<Route exact path='/login' component={Login} />
							<Route exact path='/admin' component={Admin} />
							<Route exact path='/guest' component={Guest} />
							<Route exact path='/otp' component={Otp} />
							<Route exact path='/reset-password' component={ResetPassword} />
							<Route exact path='/sign-up' component={Signup} />
							<Route exact path='/verify-otp' component={VerifyOtp} />
							<Route exact path='/logout' component={Logout} />
							<Protected>
								<Route exact path='/' component={Index} />
								<Route exact path='/screening' component={Screening} />
								<Route exact path='/symptoms' component={Screening} />
								<Route exact path='/conditions' component={Screening} />
								<Route exact path='/canada' component={Screening} />
								<Route exact path='/physical-contact' component={Screening} />
								<Route exact path='/health-care' component={Screening} />
								<Route exact path='/symptoms-moderate' component={Screening} />
								<Route exact path='/denied' component={Screening} />
								<Route exact path='/qr-denied' component={Screening} />
								<Route exact path='/guardian' component={Screening} />
								<Route exact path='/results' component={Results} />
								<Route exact path='/admin/dashboard' component={Dashboard} />
								<Route exact path='/admin/organisation' component={Organisation} />
								<Route exact path='/admin/change-password' component={ChangePwd} />
								<Route exact path='/admin/report' component={Report} />
							</Protected>
						</Switch>
					</BrowserRouter>
					<Footer />
				</AuthContext.Provider>
			</Suspense>
		</MainContext.Provider>
	)
}

export default App
